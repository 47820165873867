module.exports = {
    ALL: 'ALL',
    UK_OPEN_BANKING: 'UK_Open_Banking_Standard',
    POLISH_API: 'PolishAPI',
    NEXT_GEN_PSD2: 'NextGenPSD2',
    STET_PSD2: 'STET_PSD2_API',
    Uk_Open_Banking: 'ukOpenBankingStandard',
    Next_Gen_Psd2: 'nextGenPsd2Standard',
    Stet_Psd2: 'stetPsd2Standard',
    Polish_Api: 'polishApiStandard',
    STARTLING_BANK_API: 'Starling_Bank_API',
    CITI_HANDLOWY_PSD2: 'Citi_Handlowy_PSD2_API',
    SLOVAK_BANKING_API: 'Slovak_Banking_API_Standard',
    CZECH_OPEN_BANKING: 'Czech_Open_Banking_Standard',
    AMERICAN_EXPRESS_PSD2: 'American_Express_PSD2_API',
    BUDAPEST_BANK_API: 'Budapest_Bank_API',
    CUSTOM_FIELD_STANDARD: {
        NextGenPSD2: 'Nextgen PSD2',
        STET_PSD2_API: 'STET',
        PolishAPI: 'Polish API',
        UK_Open_Banking_Standard: 'OBIE',
    },
    INVALID_STANDARD: 'Invalid_Standard',
};
