import { TextField } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { withTranslation } from '../../../i18n';
//types
import { ISelectWrapperProps as Props } from '../types';

const SelectWrapper: React.FC<Props> = ({
    t,
    label,
    required,
    hideOptional,
    onChange,
    name,
    value,
    SelectProps,
    children,
    disabled,
    variant,
    color,
    tooltipText,
    tooltipPlacement,
    classes,
}) => {
    const [errorText] = React.useState('');

    label = required ? (
        label
    ) : (
        <div>
            <span>{label}</span>
            <span className={'optionalField'}>
                &nbsp;
                {!hideOptional && t!('common:optional')}
            </span>
        </div>
    );

    disabled = children.length === 1 ? true : disabled;
    value = children.length === 1 ? children[0].props.value : value;

    const textField = (
        <TextField
            name={name}
            value={value}
            label={label}
            error={!!errorText}
            helperText={errorText}
            onChange={onChange}
            SelectProps={SelectProps}
            disabled={disabled}
            variant={variant}
            color={color}
            classes={classes}
            select>
            {children}
        </TextField>
    );

    if (tooltipText) {
        return (
            <Tooltip title={tooltipText} placement={tooltipPlacement} arrow>
                {textField}
            </Tooltip>
        );
    }

    return textField;
};

export default withTranslation()(SelectWrapper);
