import { Grid, MenuItem } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AppController from '../../../../../base/App.controller';
import ButtonWrapper from '../../../wrappers/ButtonWrapper';
import DialogWrapper from '../../../wrappers/DialogWrapper';
import FormWrapper from '../../../wrappers/FormWrapper';
import SelectWrapper from '../../../wrappers/SelectWrapper';
import TextFieldWrapper from '../../../wrappers/TextFieldWrapper';
import { IBankStandardsProps } from '../../types';
import { Itranslation } from 'types';

const NextGenPsd2Modal: React.FC<IBankStandardsProps> = ({ AppStore, BankConfigurationStore }) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.host, [
        'bank-configuration',
        'common',
    ]);
    const { signingAlgorithms } = BankConfigurationStore!;
    const {
        handleBankRegistrationGroup,
        loading,
    } = BankConfigurationStore!.bankConfigurationModalStore;
    const {
        type,
        isOpen,
        closeModal,
        form,
        isFilled,
        isFormChanged,
        handleChange,
    } = BankConfigurationStore!.nextGenPsd2ModalStore;

    const ref = React.createRef();

    const handleSubmit = () => {
        return handleBankRegistrationGroup(AppStore, type);
    };

    return (
        <DialogWrapper
            open={isOpen}
            onClose={closeModal}
            className={'BankConfigurationModal'}
            title={type === 'add' ? t('addBankConfig') : t('updateBankConfig')}>
            <div className={'formContainer'}>
                <FormWrapper
                    className="form sectionParameters"
                    formRef={ref}
                    onSubmit={handleSubmit}
                    isValid={type === 'add' ? !!isFilled : isFormChanged}>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="country"
                                type="text"
                                label={t('country')}
                                value={form.country}
                                onChange={handleChange}
                                disabled
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="bankName"
                                type="text"
                                label={t('bankName')}
                                value={form.bankName}
                                onChange={handleChange}
                                disabled
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="qsealCertificate"
                                type="text"
                                label={t('qsealCertificate')}
                                value={form.qsealCertificate}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="qwacCertificate"
                                type="text"
                                label={t('qwacCertificate')}
                                value={form.qwacCertificate}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="jwtSigningKeyId"
                                type="text"
                                label={t('jwtSigningKeyId')}
                                value={form.jwtSigningKeyId}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectWrapper
                                name="jwtSigningAlgorithm"
                                label={t('jwtSigningAlgorithm')}
                                value={form.jwtSigningAlgorithm}
                                onChange={handleChange}
                                hideOptional>
                                {signingAlgorithms.map((s) => (
                                    <MenuItem key={s} value={s}>
                                        {s}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="signingKeyId"
                                type="text"
                                label={t('signingKeyId')}
                                value={form.signingKeyId}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="transportKeyId"
                                type="text"
                                label={t('transportKeyId')}
                                value={form.transportKeyId}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="psuIdType"
                                type="text"
                                label={t('psuIdType')}
                                value={form.psuIdType}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="psuCorporateIdType"
                                type="text"
                                label={t('psuCorporateIdType')}
                                value={form.psuCorporateIdType}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="xApiKeyAis"
                                type="text"
                                label={t('xApiKeyAis')}
                                value={form.xApiKeyAis}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="xApiKeyPis"
                                type="text"
                                label={t('xApiKeyPis')}
                                value={form.xApiKeyPis}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="clientId"
                                type="text"
                                label={t('clientId')}
                                value={form.clientId}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="clientSecret"
                                type="text"
                                label={t('clientSecret')}
                                value={form.clientSecret}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                    </Grid>
                </FormWrapper>
            </div>
            <div className={'buttonContainer'}>
                <Grid container justify="space-between" spacing={5}>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            color="secondary"
                            className={'closeButton'}
                            onClick={closeModal}>
                            {t('common:cancel')}
                        </ButtonWrapper>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            size={'large'}
                            color="primary"
                            className={'saveButton'}
                            loading={loading}
                            disabled={type === 'add' ? !isFilled : !isFormChanged}
                            onClick={handleSubmit}>
                            {t('common:save')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            </div>
        </DialogWrapper>
    );
};

export default inject('AppStore', 'BankConfigurationStore')(observer(NextGenPsd2Modal));
