import { action, configure, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import React from 'react';
//types
import { IRow } from '../types';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

class TableWrapperStore {
    @observable
    data: any[] = [];
    @observable
    pageNumber = 1;
    @observable
    currentPage = 1;
    @observable
    selected = {};
    @observable
    maxRowsPerPage = 10;

    serverSidePagination = false;

    @action
    setData = (data: any[]) => {
        this.data = data;
        if (!this.serverSidePagination) {
            this.currentPage = 1;
            this.renderPageNumbers();
        }
    };

    @action
    setMaxRows = (maxRows: number) => {
        this.maxRowsPerPage = maxRows;
    };

    @action
    setPageNumber = (pageNumber: number, currentPage?: number) => {
        this.serverSidePagination = true;
        this.pageNumber = pageNumber;
        this.currentPage = currentPage || 1;
    };

    @action
    handleClickPage = (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>, navigation: string) => {
        if (navigation === 'before') {
            this.currentPage -= 1;
        } else {
            this.currentPage += 1;
        }
    };

    @action
    updateSelected = (row: IRow) => {
        this.selected = row;
    };

    @action
    toggleSubsection = (index: number) => {
        this.data[index].isSectionOpen = !this.data[index].isSectionOpen;
    };

    @action
    toggleNestedChildren = (row: IRow) => {
        row.isSectionOpen = !row.isSectionOpen;
        this.toggleNestedParent(row);
    };

    @action
    toggleNestedParent = (row: IRow) => {
        if (!row.isSectionOpen) {
            row.childrenList?.map((s) => {
                s.isSectionOpen = false;
                if (s.childrenList?.length > 0) {
                    this.toggleNestedParent(s);
                }
            });
        }
    };

    renderPageNumbers() {
        this.pageNumber = Math.ceil(this.data.length / this.maxRowsPerPage);
    }
}

export default TableWrapperStore;
