import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Spinner from '../spinner/Spinner';
//types
import { IButtonWrapperProps as Props } from '../types';

const styles = () => ({
    root: {
        display: 'block',
    },
});

const ButtonWrapper: React.FC<Props> = ({
    loading,
    color = 'primary',
    children,
    tooltipText,
    className,
    showTooltip,
    classes,
    ...props
}) => {
    const buttonField = (
        <Button className={className} variant="contained" color={color} {...props}>
            {loading ? <Spinner type={Spinner.TYPE_SMALL} color={color} /> : children}
        </Button>
    );

    if (tooltipText && (showTooltip || !props.disabled)) {
        return (
            <Tooltip title={tooltipText} arrow>
                <span className={classes.root}>{buttonField}</span>
            </Tooltip>
        );
    }

    return buttonField;
};

export default withStyles(styles, { name: 'ButtonWrapper' })(ButtonWrapper);
