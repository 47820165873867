export default function asyncReadFile(inputFile: any, isImage: boolean = false) {
    const newFileReady = new FileReader();

    return new Promise((resolve, reject) => {
        newFileReady.onerror = () => {
            newFileReady.abort();
            reject(new DOMException('Problem parsing input file.'));
        };

        newFileReady.onload = () => {
            resolve(newFileReady.result);
        };

        if (isImage) {
            newFileReady.readAsDataURL(inputFile);
        } else {
            newFileReady.readAsText(inputFile);
        }
    });
}
