import { Grid, MenuItem } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import AppController from '../../../../../base/App.controller';
import ButtonWrapper from '../../../wrappers/ButtonWrapper';
import DialogWrapper from '../../../wrappers/DialogWrapper';
import FormWrapper from '../../../wrappers/FormWrapper';
import SelectWrapper from '../../../wrappers/SelectWrapper';
import TextFieldWrapper from '../../../wrappers/TextFieldWrapper';

function UKOpenBankingModal({ AppStore, BankConfigurationStore }) {
    const { t } = AppController.getTranslation(AppStore.host, ['bank-configuration', 'common']);
    const { signingAlgorithms } = BankConfigurationStore;
    const {
        handleBankRegistrationGroup,
        loading,
    } = BankConfigurationStore.bankConfigurationModalStore;
    const {
        type,
        isOpen,
        closeModal,
        form,
        isFilled,
        handleChange,
    } = BankConfigurationStore.ukOpenBankingModalStore;

    const ref = React.createRef();

    const handleSubmit = () => {
        return handleBankRegistrationGroup(AppStore, type);
    };

    const authenticationTypes = [
        'client_secret_basic',
        'client_secret_post',
        'tls_client_auth',
        'private_key_jwt',
    ];

    return (
        <DialogWrapper
            open={isOpen}
            onClose={closeModal}
            className={'BankConfigurationModal'}
            title={type === 'add' ? t('addBankConfig') : t('updateBankConfig')}>
            <div className={'formContainer'}>
                <FormWrapper
                    className="form sectionParameters"
                    formRef={ref}
                    onSubmit={handleSubmit}
                    isValid={isFilled}>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="country"
                                type="text"
                                label={t('country')}
                                value={form.country}
                                onChange={handleChange}
                                disabled
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="bankName"
                                type="text"
                                label={t('bankName')}
                                value={form.bankName}
                                onChange={handleChange}
                                disabled
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="organisationId"
                                type="text"
                                label={t('organisationId')}
                                value={form.organisationId}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="softwareStatementId"
                                type="text"
                                label={t('softwareStatementId')}
                                value={form.softwareStatementId}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="jwtSigningKeyId"
                                type="text"
                                label={t('jwtSigningKeyId')}
                                value={form.jwtSigningKeyId}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectWrapper
                                name="jwtSigningAlgorithm"
                                label={t('jwtSigningAlgorithm')}
                                value={form.jwtSigningAlgorithm}
                                onChange={handleChange}
                                hideOptional>
                                {signingAlgorithms.map((s) => (
                                    <MenuItem key={s} value={s}>
                                        {s}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="signingKeyId"
                                type="text"
                                label={t('signingKeyId')}
                                value={form.signingKeyId}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWrapper
                                name="transportKeyId"
                                type="text"
                                label={t('transportKeyId')}
                                value={form.transportKeyId}
                                onChange={handleChange}
                                hideOptional
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectWrapper
                                name="authenticationType"
                                label={t('authenticationType')}
                                value={form.authenticationType}
                                onChange={handleChange}
                                hideOptional>
                                {authenticationTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {t(type)}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                        <Grid item xs={6} hidden={!form.authenticationType}>
                            <TextFieldWrapper
                                name="clientId"
                                type="text"
                                label={t('clientId')}
                                value={form.clientId}
                                onChange={handleChange}
                                required={['client_secret_basic', 'client_secret_post'].includes(
                                    form.authenticationType,
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} hidden={!form.authenticationType}>
                            <TextFieldWrapper
                                name="clientSecret"
                                type="text"
                                label={t('clientSecret')}
                                value={form.clientSecret}
                                onChange={handleChange}
                                required={['client_secret_basic', 'client_secret_post'].includes(
                                    form.authenticationType,
                                )}
                            />
                        </Grid>
                    </Grid>
                </FormWrapper>
            </div>
            <div className={'buttonContainer'}>
                <Grid container justify="space-between" spacing={5}>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            color="secondary"
                            className={'closeButton'}
                            onClick={closeModal}>
                            {t('common:cancel')}
                        </ButtonWrapper>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            size={'large'}
                            color="primary"
                            className={'saveButton'}
                            loading={loading}
                            disabled={!isFilled}
                            onClick={handleSubmit}>
                            {t('common:save')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            </div>
        </DialogWrapper>
    );
}

UKOpenBankingModal.propTypes = {
    AppStore: PropTypes.object.isRequired,
    BankConfigurationStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'BankConfigurationStore')(observer(UKOpenBankingModal));
