import React from 'react';
//types
import { IFormWrapperProps as Props } from '../types';

const FormWrapper: React.FC<Props> = ({ onSubmit, formRef, isValid, className, children }) => {
    const handleSubmit = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && isValid) {
            event.preventDefault();
            onSubmit(event);
        }
    };

    return (
        <form
            onSubmit={(event) => event.preventDefault()}
            className={className}
            ref={formRef}
            onKeyDown={handleSubmit}>
            {children}
        </form>
    );
};

export default FormWrapper;
