module.exports = {
    PEM: 'PEM',
    QWAC: 'QWAC',
    QSEAL: 'QSEAL',
    OBWAC: 'OBWAC',
    OBSEAL: 'OBSEAL',
    CSR_CREATED: 'CSR Created',
    CERT_UPLOADED: 'Certificate Uploaded',
    REFUND: 'Refund',
};
