import { Divider } from '@material-ui/core';
import React from 'react';
import arrowIcon from '../../../images/icon-arrow.svg';
import Icon from '../wrappers/IconWrapper';
import { IBreadcrumbs as Props } from '../types';

const Breadcrumbs: React.FC<Props> = ({ previousStep, parentPath, currentPath }) => {
    const parentPaths = () => {
        let paths = '';
        if (Array.isArray(parentPath)) {
            paths = parentPath.join(' / ');
        } else {
            paths = parentPath.concat(' / ');
        }

        return paths;
    };

    return (
        <div className={'breadcrumb'}>
            <span className="icon" onClick={previousStep}>
                <Icon icon={arrowIcon} stroke />
            </span>
            <Divider orientation="vertical" className={'divider'} />
            <div>
                <div className={'parentNav'}>{parentPaths()}</div>
                <div className={'childNav'}>{currentPath}</div>
            </div>
        </div>
    );
};

export default Breadcrumbs;
